import React from "react";
import './b1_profile_data.css'
import BaseData from '../../components/basedata'
import MoreData from '../../components/moredata'
import ContactData from '../../components/contactdata'
import LayoutB1Profile from "../../components/layout-b1-profile"

export default class B1ProfileData extends React.Component{
  state={
    dataIndex:0,
    showwarning:false
  }
  changeDataIndex=(e)=>{
    this.setState({
      dataIndex:e
    })
  }



  render() {
    return(
      <LayoutB1Profile location={this.props.location} >
        <div className="enroll-data">
          <div className="enroll-data-menu">
            <div className="menu-item menu-item-left" onClick={()=>this.changeDataIndex(0)} style={{background:this.state.dataIndex===0?'black':'white',color:this.state.dataIndex===0?'white':'black'}}>证件信息</div>
            <div className="menu-item menu-item-center" onClick={()=>this.changeDataIndex(1)} style={{background:this.state.dataIndex===1?'black':'white',color:this.state.dataIndex===1?'white':'black'}}>补充信息</div>
            <div className="menu-item menu-item-right" onClick={()=>this.changeDataIndex(2)} style={{background:this.state.dataIndex===2?'black':'white',color:this.state.dataIndex===2?'white':'black'}}>联系方式</div>
          </div>
          <div className="enroll-data-container" >
            {
              this.state.dataIndex===0&&<div >
                <BaseData />
              </div>
            }

            {
              this.state.dataIndex===1&&<div>
                <MoreData />
              </div>
            }

            {
              this.state.dataIndex===2&&<div>
                <ContactData />
              </div>
            }
          </div>

        </div>
      </LayoutB1Profile>
    )
  }
}