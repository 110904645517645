import React from "react";
import './basedata.css'
// import { DatePicker,Modal,Cascader,Upload, Button, Icon } from 'antd'
// import locale from 'antd/es/date-picker/locale/zh_CN'
// import moment from 'moment'


export default class ContactData extends React.Component{
    state={

    }
    submitData=()=>{
        this.setState({
            showwarning:true
        })
    }
    render() {
        return(
            <div>
                <div className="info-input">
                    <div style={{flex:1}} className="info-input-title">
                        <div>手机号码:</div>
                    </div>
                    <input className="info-edit" value={this.state.phone||''} onChange={this.phoneInput} placeholder="请填写正确的手机号码"/>
                </div>
                <div className="info-input">
                    <div style={{flex:1}} className="info-input-title">
                        <div>邮箱:</div>
                    </div>
                    <input className="info-edit" value={this.state.email||''} onChange={this.emailInput}  placeholder="请填写正确的邮箱"/>
                </div>
                <div className="info-input">
                    <div style={{flex:1}} className="info-input-title">
                        <div>紧急联系人:</div>
                    </div>
                    <input className="info-edit" value={this.state.linkman||''} onChange={this.linkmanInput} placeholder="请填写紧急联系人姓名"/>
                </div>
                <div className="info-input">
                    <div style={{flex:1}} className="info-input-title">
                        <div>联系人号码:</div>
                    </div>
                    <input className="info-edit" value={this.state.linkmanphone||''} onChange={this.linkmanPhoneInput} placeholder="请填写正确的号码"/>
                </div>
                <div className="info-input">
                    <div style={{flex:1}} className="info-input-title">
                        <div>联系人关系:</div>
                    </div>
                    <div className="info-select">
                        <select value={this.state.linkmanrelationship} onChange={this.selectLinkmanRelationship}>
                            <option value="" disabled  hidden>请选择联系人关系</option>
                            <option>父母</option>
                            <option>夫妻</option>
                            <option>兄弟姐妹及其他亲友</option>
                            <option>同事</option>
                            <option>朋友</option>
                        </select>
                    </div>
                </div>

                <div className="data-submit-btn" onClick={()=>this.submitData(2)}>保存</div>
            </div>
        )
    }
}