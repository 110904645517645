import React from "react";
import './layout-b1-profile.css'
import MyNavLink from './my-nav-link'
import LayoutB1 from "./layout-b1"

export default class layoutB1Profile extends React.Component{
  render() {
    const { location, title, children } = this.props
    return(
      <LayoutB1 location={this.props.location} >
        <div className="b1-profile-container">
          <div className="avatar-phone">
            <div className="edit-avatar">
              <img className="avatar" alt="头像" src={require('../images/b1/avatar.png')}/>
              <div className="edit-text">修改头像</div>
            </div>
            <div>18888888888</div>
          </div>
          <div className="profile-menu">
            <MyNavLink  to='/demos/themeB1/profile/enroll_list' className="profile-menu-item">
              <div className="profile-menu-name">
                <img alt="enroll" src={require('../images/b1/enroll.svg')}/>
                <div>我的报名</div>
              </div>
              <div className="b1-menu-item-shadow"></div>
            </MyNavLink>
            <MyNavLink  to='/demos/themeB1/profile/certificates' className="profile-menu-item">
              <div className="profile-menu-name">
                <img alt="certificate" src={require('../images/b1/query_results.svg')}/>
                <div>个人证书</div>
              </div>
              <div className="b1-menu-item-shadow"></div>
            </MyNavLink>
            <MyNavLink  to='/demos/themeB1/profile/data' className="profile-menu-item">
              <div className="profile-menu-name">
                <img alt="data" src={require('../images/b1/enroll_data.svg')}/>
                <div>参赛资料</div>
              </div>
              <div className="b1-menu-item-shadow"></div>
            </MyNavLink>
            <MyNavLink  to='/demos/themeB1/profile/account' className="profile-menu-item">
              <div className="profile-menu-name">
                <img alt="data" src={require('../images/b1/user_data.svg')}/>
                <div>账户信息</div>
              </div>
              <div className="b1-menu-item-shadow"></div>
            </MyNavLink>
          </div>
          <main >{children}</main>
        </div>


      </LayoutB1>
    )
  }
}