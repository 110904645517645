import React from "react";
import './basedata.css'
import { Cascader } from 'antd'
import options from '../data/cascader-address-options'

export default class MoreData extends React.Component{
    state={

    }
    submitData=()=>{
        this.setState({
            showwarning:true
        })
    }
    render() {
        const color='black'
        return(
            <div>
                <div className="info-input">
                    <div style={{flex:1}} className="info-input-title">
                        <span style={{color:color}}>*</span>
                        <div>血型:</div>
                    </div>
                    <div className="info-select">
                        <select value={this.state.blood} onChange={this.selectBlood}>
                            <option value="" disabled  hidden>请选择血型</option>
                            <option value='O'>O</option>
                            <option value='A'>A</option>
                            <option value='B'>B</option>
                            <option value='AB'>AB</option>
                        </select>
                    </div>
                    {!this.state.blood&&this.state.showwarning&&<div className="warning-text" >未选择血型</div>}
                </div>
                <div className="info-input">
                    <div style={{flex:1}} className="info-input-title">
                        <span style={{color:color}}>*</span>
                        <div>国家地区:</div>
                    </div>
                    <div className="info-country" >
                        <Cascader options={options}  style={{border:"none",width:'100%',height:'100%'}} onChange={this.selectCity} placeholder="请输入国家地区" />
                    </div>
                    {/*<input className="info-edit" value={this.state.country||''} onChange={this.selectCountry} placeholder="请输入国家地区"/>*/}
                    {!this.state.country&&this.state.showwarning&&<div className="warning-text" >未选择国家地区</div>}
                </div>
                <div className="info-input">
                    <div style={{flex:1}} className="info-input-title">
                        <div>职业:</div>
                    </div>
                    <input className="info-edit" value={this.state.job||''} onChange={this.jobInput} placeholder="请填写职业"/>
                </div>
                <div className="info-input">
                    <div style={{flex:1}} className="info-input-title">
                        <div>学历:</div>
                    </div>
                    <div className="info-select">
                        <select value={this.state.education} onChange={this.selectEducation}>
                            <option value="" disabled  hidden>请选择学历</option>
                            <option>小学</option>
                            <option>初中</option>
                            <option>高中</option>
                            <option>大学</option>
                            <option>硕士及以上</option>
                        </select>
                    </div>
                </div>
                <div className="info-input">
                    <div style={{flex:1}} className="info-input-title">
                        <span style={{color:color}}>*</span>
                        <div>衣服尺码:</div>
                    </div>
                    <div className="info-select">
                        <select value={this.state.clothsize} onChange={this.selectClothsize}>
                            <option value="" disabled hidden>请选择衣服尺寸</option>
                            <option>S</option>
                            <option>M</option>
                            <option>L</option>
                            <option>XL</option>
                            <option>XXL</option>
                            <option>XXXL</option>
                        </select>
                    </div>
                    {!this.state.clothsize&&this.state.showwarning&&<div className="warning-text" >未选择衣服尺码</div>}
                </div>
                <div className="info-input">
                    <div style={{flex:1}} className="info-input-title">
                        <div>居住地:</div>
                    </div>
                    <input className="info-edit" value={this.state.location||''} onChange={this.locationInput} placeholder="请输入居住地址"/>
                </div>
                <div className="info-input">
                    <div style={{flex:1}} className="info-input-title">
                        <div>月收入:</div>
                    </div>
                    <div className="info-select">
                        <select value={this.state.income} onChange={this.selectIncome}>
                            <option value="" disabled  hidden>请选择月收入</option>
                            <option>3000以下</option>
                            <option>3000~5000</option>
                            <option>5000~10000</option>
                            <option>10000+</option>
                        </select>
                    </div>
                </div>

                <div className="data-submit-btn" onClick={()=>this.submitData(1)}>保存</div>
            </div>
        )
    }
}