import React from "react";
import './basedata.css'
import { DatePicker,Modal,Cascader,Upload, Button, Icon } from 'antd'
import locale from 'antd/es/date-picker/locale/zh_CN'
import moment from 'moment'


export default class BaseData extends React.Component{
    state={

    }
    submitData=()=>{
        this.setState({
            showwarning:true
        })
    }
    render() {
        const color='black'
        const dateFormat='YYYY-MM-DD'
        return(
            <div>
                <div className="info-input">
                    <div style={{flex:1}} className="info-input-title" >
                        <span style={{color:color}}>*</span>
                        <div>姓名:</div>
                    </div>
                    <input  className="info-edit" placeholder="请输入选手姓名"  value={this.state.name||''} onChange={this.nameInput}/>
                    {!this.state.name&&this.state.showwarning&&<div className="warning-text" >未填写</div>}
                </div>

                <div className="info-input">
                    <div style={{flex:1}} className="info-input-title">
                        <span style={{color:color}}>*</span>
                        <div>性别:</div>
                    </div>
                    <div className="info-sex">
                        <div className="sex-radio-container"><input type='radio' className="radio" checked={this.state.sex==='man'} value="man" onChange={this.selectSex}/>男</div>
                        <div className="sex-radio-container"><input type="radio" className="radio" checked={this.state.sex==='female'} value="female" onChange={this.selectSex}/>女</div>
                    </div>
                    {!this.state.sex&&this.state.showwarning&&<div className="warning-text" >未选择</div>}
                </div>
                <div className="info-input">
                    <div style={{flex:1}} className="info-input-title">
                        <span style={{color:color}}>*</span>
                        <div>证件类型:</div>
                    </div>
                    <div className="info-select">
                        <select onChange={this.selectIdType} value={this.state.idType}>
                            <option value="idCard">身份证</option>
                            <option value="passport">护照</option>
                        </select>
                    </div>

                </div>
                <div className="info-input">
                    <div style={{flex:1}} className="info-input-title">
                        <span style={{color:color}}>*</span>
                        <div>证件号码:</div>
                    </div>
                    <input className="info-edit" placeholder="请填写证件号码" value={this.state.idNum||''} onChange={this.idNumInput}/>
                    {!this.state.idNum&&this.state.showwarning&&<div className="warning-text" >未填写</div>}
                </div>
                <div className="info-input">
                    <div style={{flex:1}} className="info-input-title">
                        <span style={{color:color}}>*</span>
                        <div>出生日期:</div>
                    </div>
                    <div className="select-birth">
                        <DatePicker className="date-picker" onChange={this.selectBirth} locale={locale} placeholder="请选择出生日期" value={this.state.birth?moment(this.state.birth, dateFormat):null} defaultValue={null} format={dateFormat}/>
                    </div>
                    {!this.state.birth&&this.state.showwarning&&<div className="warning-text" >未选择日期</div>}
                </div>

                <div className="data-submit-btn" onClick={()=>this.submitData(0)}>保存</div>
            </div>
        )
    }
}